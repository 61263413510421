<!--
 * @Descripttion: 登录页面
 * @version: 
 * @Author: Chen
 * @Date: 2020-09-14 10:33:34
 * @LastEditors: wuruoyu
 * @LastEditTime: 2021-01-05 11:20:56
-->
<template>
  <div class="login">
    <header>
      <img :src="logo"/>
    </header>
    <main>
      <div class="content">
        <div class="brand"></div>
        <div class="formbox">
          <div class="title">
            <p>华云慧能平台</p>
            <el-divider>登<span style="marginLeft:2rem">录</span></el-divider>
          </div>
          <div class="inner">
            <div class="input-row">
              <el-input
                placeholder="请输入账号"
                prefix-icon="el-icon-s-custom"
                v-model="name">
              </el-input>
            </div>
            <div class="input-row">
              <el-input
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                show-password
                @keyup.enter.native="handleLogin"
                v-model="password">
              </el-input>
              <a class="forget">忘记密码</a>
            </div>
            <el-button type="primary" @click="handleLogin">登录</el-button>
          </div>
          <div class="info">
            <ul>
              <li>请使用管理员所创建账号进行登录</li>
              <li>初次登录请使用初始密码登录</li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <span>copyright@2020</span>
      <span>xxxx</span>
    </footer>
  </div>
</template>

<script>
import { loginApi } from "@/api/loginAPI";
import md5 from 'js-md5'
export default {
  data() {
    return {
      logo:require("@/assets/images/login/logo.png"),
      name:'',
      password:'',
      salt:'siact'
    }
  },
  methods:{
    handleLogin(){
      let name = this.name.trim();
      let password = this.password.trim();
      if(name && password){
        this.loginFn();
      }else{
        this.$message({
          type:'info',
          message:'账号和密码不能为空'
        })
      }
    },
    loginFn(){
      let params = {
        account:this.name,
        password:md5(this.password + this.salt),
        code:'',
        mark:'plat',
        type:'PC'
      };
      loginApi(params).then(res => {
        let resdata = res.result;
        let userinfo = resdata.accountInfo;
        userinfo.loginTime = new Date().getTime();
        return new Promise((reslove,reject) => {
          this.$store.commit('TENANT_ID',userinfo.tenantId)
          window.localStorage.setItem('userinfo',JSON.stringify(userinfo))
          window.localStorage.setItem('loginData',JSON.stringify(resdata))
          window.localStorage.setItem('time',JSON.stringify(new Date().getTime()));
          this.setCookie('plat', JSON.stringify({
            account: userinfo.account,
            jumpFrom: ''
          }), 3)
          reslove()
        })
      }).then(()=>{
        this.$router.push({path:'/platformmanage/personnal'})
      })
    },
    setCookie(c_name, value, expiredays) {
      var exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = c_name+ "=" + escape(value) +
      ((expiredays==null) ? "" : ";expires="+exdate.toGMTString())
    }
  }
}
</script>

<style lang="scss" scoped>
  .login{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #000000;
    header{
      height: 2.8rem;
      display: flex;
      align-items: center;
      padding-left: 20px;
      img{
        width: 8.125rem;
        height: 1.25rem;
      }
    }
    main{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("~@/assets/images/login/mainbg.png") no-repeat center center/cover;
    }
    .content{
      width: 62%;
      height: 90%;
      display: flex;
      border-radius: 12px;
      box-shadow: 0 0 50px #000;
      .brand{
        flex: 3;
        height: 100%;
        background: url("~@/assets/images/login/brandbg.png") no-repeat center center/cover;
      }
      .formbox{
        flex: 2;
        height: 100%;
        background: url("~@/assets/images/login/formbg.png") no-repeat center center/cover;
      }
      .title{
        height: 30%;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        p{
          font-size: 2rem;
          padding: 2rem 0;
          text-align: center;
        }
      }
      .inner{
        height: 44%;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        .input-row{
          margin-top: 2rem;
          position: relative;
          padding-bottom: 20px;
        }
        .forget{
          position: absolute;
          bottom: -5px;
          right: 0;
          font-size: 0.875rem;
          color: #037CFF;
          cursor: pointer;
        }
        .el-button{
          height: 3.5rem;
          margin-top: 3rem;
        }
      }
      .info{
        height: 15%;
        padding: 0 2rem;
        ul{
          border-top: 2px solid #353535;
          margin-top: 10%;
          padding: 0 1rem;
          li{
            margin-top: 1rem;
            color: #999999;
            font-size: 0.875rem;
          }
        }
      }
    }
    footer{
      height: 2.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      font-size: 0.875rem;
      color: #f2f2f2;
    }
    .el-divider{
      background-color: #353535;
    }
    & /deep/ .el-divider__text{
      background-color: #0D0D0D;
      padding: 0 20px;
      font-weight: 500;
      color: #ffffff;
      font-size: 1.5rem;
    }
    & /deep/ .el-divider--horizontal{
      height: 2px;
    }
    & /deep/ .el-input__inner{
      border-radius: 0;
      padding-left: 3rem;
      border-bottom: 2px solid #4F4F4F;
      border-top: none;
      border-left: none;
      border-right: none;
      background: transparent;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #f2f2f2;
    }
    & /deep/ .el-input__prefix{
      font-size: 1.2rem;
    }
  }
</style>

