/*
 * @Author: wuruoyu
 * @Description: 登录
 * @Date: 2020-12-30 11:49:56
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-30 15:36:38
 * @FilePath: \huayun_platform\src\api\loginAPI.js
 */

import {
  get
} from './request';
//登陆
export const loginApi = (params) => {
  return get('/plat/user/login', params)
}